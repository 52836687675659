//
// icons.scss
//

@import "icons/dripicons";
@import "icons/materialdesignicons";
@import "icons/feather";


// Additional Icons
.mce-i-code-braces {
    @extend .mdi-code-braces;
    &::before {
        font-family: "Material Design Icons";
    }
}

